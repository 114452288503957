import { isProduction } from '$lib/environment'
import { handleErrorWithSentry, replayIntegration, init } from '@sentry/sveltekit'
import type { HandleClientError } from '@sveltejs/kit'

init({
    dsn: 'https://eef5e1ca15fc21a47974c8904716d1ea@o4508279419043840.ingest.us.sentry.io/4508279420813312',

    tracesSampleRate: 1.0,

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,

    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,

    // If you don't want to use Session Replay, just remove the line below:
    integrations: [replayIntegration()],
    enabled: isProduction,
    environment: process.env.NODE_ENV,
})

// Pass a function wrapper to handleErrorWithSentry
export const handleError: HandleClientError = handleErrorWithSentry(({ error, event: _event }) => {
    // Check for the specific module import error (case-insensitive, more robust regex)
    if (error instanceof TypeError && /import.*module.*script.*failed/i.test((error as Error).message)) {
        console.warn('Detected module import failure, likely due to stale cache after deployment. Forcing page reload.')
        // Use setTimeout to allow console logging and potentially other async tasks to finish before reload
        setTimeout(() => {
            // Ensure we are in a browser context before reloading
            if (typeof window !== 'undefined') {
                window.location.reload() // Force a hard reload
            }
        }, 50)

        // Optionally return a simple object to satisfy the type, although Sentry might ignore it
        // when the page is reloading anyway.
        return {
            message: 'Please hold on while we reload the page.',
        }
    }

    // For errors not handled above, Sentry's default behavior within the wrapper will take over.
    // No explicit return is needed here for Sentry to process the error.
})
